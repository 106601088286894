/* eslint-disable react/prop-types */
import React from "react";

import Typography from "root/components/Typography";
import Grid from "root/components/Grid";

import styles from "./index.module.css";

const Hero = ({ children, title, subtitle, color }) => {
  // MAIN RENDER
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <Grid columns={45} lines={18} color="yellow" opacity={0.5} />
        </div>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography weight="medium" variant="h1" color={color}>
              {title}
            </Typography>
            {subtitle && (
              <Typography weight="medium" variant="h2" color={color}>
                {subtitle}
              </Typography>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Hero;
