/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function TermsAndConditions({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("es-ES");
  }, []);

  return (
    <LegalDocument
      title={t("pages.termsConditions.label")}
      bodyTitle={t("pages.termsConditions.title")}
      currentPage="termsConditions"
      launcher={launcher}
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Las presentes Condiciones Generales de Uso de las soluciones Sioslife®
          deben leerse detenidamente antes de usar cualquiera de las soluciones
          Sioslife®. La utilización de las soluciones Sioslife® indica que el
          Usuario tiene conocimiento de estas Condiciones de Uso y que concorda
          con las mismas. Estas Condiciones Generales de Uso se encuentran
          publicadas en &quot;
          <a href={launcher ? "#" : "https://sioslife.com"}>
            https://sioslife.com
          </a>
          &quot; y se pueden consultar en cualquier momento.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Partes
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Son partes en estas Condiciones Generales: La empresa Sioslife S.A. y
          el Usuario, que accede a las plataformas Sioslife® en calidad de
          usuario de las soluciones Sioslife®, familiar o amigo/conocido de un
          usuario de las soluciones Sioslife®, o el Usuario que, en calidd de
          profesional de una entidad o institución que cuenta con soluciones
          Sioslife®, accede al área de empleado en la plataforma online, en las
          aplicaciones móviles o en el sistema instalado en esta misma entidad o
          institución.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. Objeto
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Las presentes condiciones regulan la licencia de utilización con
          carácter no exclusivo y no transferible de las soluciones Sioslife®.
          La licencia de utilización de las soluciones Sioslife® podrá ser
          gratuita u onerosa, dependiendo del tipo de usuario, de la solución y
          de las condiciones comerciales acordadas y contratadas entre las
          partes.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Definiciones
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Las soluciones Sioslife® son sistemas interactivos que resultan de la
          combinación de hardware y software o bien solamente software, y que
          podrán estar disponibles en un ambiente de uso fijo (desktop) o de uso
          móvil (tablet o smartphone). El Software Sioslife® incluye diversas
          funcionalidades destinadas a combatir el alejamiento social y el
          sedentarismo de la población mayor. Estas funcionalidades están
          personalizadas de acuerdo con las características de cada usuario. En
          torno a cada usuario, sus familiares, amigos y prestadores de
          cuidados, tiene la posibilidad de realizar comunicación,
          monitorización y acompañamiento del día a día y de las actividades de
          los mayores, así como funcionalidades de gestión y configuración del
          sistema.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. Descripción y Requisitos de Funcionamiento de las Soluciones
        Sioslife®
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          El software Sioslife®, parte integrante de las soluciones Sioslife®,
          funciona en los sistemas operativos Windows, Android y iOS, así como
          en los navegadores de internet más habituales. La aplicación permite
          el registro de datos de texto y subir ficheros de imagen y vídeo. En
          lo que se refiere al output de dados, todos tienen lugar a nivel
          gráfico y visual, estando determinado el aspecto gráfico por la
          estructura de la aplicación. El software no tiene necesidades
          específicas para funcionar, siendo solamente necesario un dispositivo
          con conexión a Internet y con posibilidad de realizar registros de
          texto. La aplicación garantiza su funcionamiento en Tablets,
          Desktops/Laptops y smartphones. La transmisión de datos en la
          plataforma se realiza de forma segura, enviándose los datos de forma
          encriptada.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. Adquisición de las Licencias Sioslife®
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          El suministro del servicio de suscripción de las soluciones Sioslife®
          podrá depender del pago previo por parte del Usuario de una licencia
          de uso.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. Obligaciones de la Empresa
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Todos los datos se guardan en una base de datos encriptada, siendo de
          la responsabilidad de Sioslife S.A. guardar toda la información
          introducida por el Usuario en la plataforma. Sioslife S.A. garantiza
          la confidencialidad de todos los datos introducidos por el Usuario en
          la plataforma. Todo el interfaz de usuario ha sido creado pensando en
          facilitar la realización de todas las funcionalidades. Sioslife S.A.
          garantiza que, dentro de las limitaciones de la aplicación, todas las
          funcionalidades funcionarán como se estipula en la página de ayuda del
          Software Sioslife®.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Responsabilidad de la Empresa
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          La suspensión del servicio ofrecido a través de las plataformas
          Sioslife® por razones no imputables a Sioslife S.A. no le otorga al
          Usuario el derecho a indemnización o compensación, de cualquier forma,
          por cualquier daño, patrimonial o de otro tipo con incidencia en el
          Usuario en virtud de la referida suspensión. Sioslife S.A. no responde
          ante terceros por cualquier daño o perjuicio causado por actos del
          Usuario que resulten en un uso abusivo de la plataforma o en la
          violación de los términos y condiciones del presente Contrato de
          Licencia de Uso. En caso de la eliminación por el Usuario de cualquier
          información o dato previamente introducido por él en la plataforma,
          Sioslife S.A. no tendrá la obligación de recuperar dicha información.
          Sioslife S.A. no se responsabiliza por un funcionamiento adecuado de
          la aplicación en navegadores y sistemas operativos diferentes a los
          especificados en el punto 4. Sioslife S.A. no se responsabiliza por el
          procesamiento adecuado de la información por razones imputadas a la
          velocidad de la conexión de internet del Usuario.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. Obligaciones del Usuario
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          El acceso a la plataforma se realiza cuando el usuario digita su
          identificación y su contraseña de acceso válida, o a través del
          Sistema de Acceso Simplificado construidos para usuarios mayores, con
          el que no es necesario introducir credenciales tales como el nombre de
          usuario y la contraseña. El Usuario tiene autorización y capacidad
          para modificar la contraseña en cualquier momento. El Usuario tiene la
          posibilidad de recuperar la contraseña. Sioslife S.A. recomienda que
          el Usuario cambie la contraseña regularmente y que no divulgue sus
          datos de acceso a ninguna persona que no esté autorizada a practicar
          actos en su nombre. El Usuario acepta toda la responsabilidad por la
          seguridad de identificación del Usuario y de la respectiva contraseña.
          Se incluyen en este caso el acceso no autorizado por terceros, aunque
          se trate de una persona que actúe por cuenta, en nombre, en
          representación o bajo las instrucciones derivadas del vínculo laboral
          o de otro tipo con el Usuario. Toda la actividad de archivo de datos y
          medios del Software Sioslife® es de la responsabilidad del Usuario. El
          Usuario concuerda en no usar la plataforma para: Difamar, abusar,
          asediar, perseguir, amenazar o violar, bajo cualquier forma, los
          derechos de otro; Publicar, colocar un post, subir, distribuir o
          difundir cualquier tipo de material y / o información inadecuada,
          difamatoria, obscena, ilegal o irrespetuosa; Subir ficheros o
          cualquier otro tipo de material que se encuentre protegido por
          Derechos de Autor o que viole la legislación de protección de datos
          personales/privacidad y cualesquier otros derechos de propiedad, con
          excepción de los casos en los que el Usuario esté en posesión o haya
          recibido la respectiva autorización para hacerlo; Subir ficheros que
          contengan virus, troyanos, gusanos, bombas de tiempo, cancelbots,
          archivos corrompidos o cualquier otro tipo de archivos idénticos que
          puedan dañar el ordenador, smartphone, tablet, dispositivo similar o
          la propiedad de otro; Realizar descarga ilegal, o sujeta a cualquier
          otra prohibición, de ficheros pertenecientes a terceros o a los demás
          usuarios. Al Usuario no le está permitido: Falsificar, omitir o borrar
          cualquier atribución de autoría, legal o de cualquier otro tipo de
          etiquetas/designaciones de propiedad en el origen/fuente del
          software/material contenido en un fichero cargado; Manipular o
          falsificar información con el objetivo de ocultar el origen de los
          productos o servicios; Grabar, recopilar, retener o coleccionar
          información sobre otros usuarios; Violar cualquiera de las leyes /
          regulaciones aplicables, en particular aquellas que regulan la
          protección y los derechos de autor, así como corromper o alterar los
          procedimientos, políticas, requisitos o regulaciones relacionados con
          los servicios; Crear una identidad falsa con el propósito de engañar a
          otros.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Licenciamiento y Derechos de Autor
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          El Software Sioslife® no se vende, sino que se licencia al Usuario.
          Esta licencia entra en vigor a partir de la fecha de aceptación del
          Contrato de Licencia de uso y del respectivo pago integral. El Usuario
          no podrá, excepto autorización expresa de Sioslife S.A. concedida al
          efecto: Copiar cualquier parte del software; Distribuir, alquilar,
          sublicenciar, transferir o divulgar, mediante cualquier método, o
          transmitir el Software Sioslife® a cualquier persona; Modificar,
          traducir, fusionar o preparar trabajos derivados del Software
          Sioslife®; Usar cualquier parte del software para fines no
          especificados y/o autorizados por Sioslife S.A.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Alteraciones a las Condiciones Generales
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife S.A. podrá modificar estas Condiciones Generales de
          Utilización en el futuro. Cualquier alteración o modificación
          contractual será comunicada al Usuario a través de un aviso online. El
          Usuario concuerda en que el uso de las soluciones Sioslife®, después
          de haber recibido notificación de alteración, enmienda o ampliacion de
          este contrato, procederá a la aceptación de dichas alteraciones,
          enmiendas o ampliaciones.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Privacidad y Derecho de Acceso a los Datos
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Por el presente contrato, el Usuario concuerda en que Sioslife S.A.
          pueda recopilar y usar informaciones técnicas que sean suministradas
          por usted sobre los servicios de soporte relacionados con el Software
          Sioslife®. Los datos personales recopilados por Sioslife S.A. constan
          de una base de datos protegida, siendo confidenciales y usándose
          exclusivamente para el cumplimiento de las obligaciones legales de
          Sioslife S.A. y procesándose automáticamente de acuerdo con la Ley nº
          58/2019, de 08 de agosto y con el Reglamento (UE) 2016/679 del
          Parlamento y del Consejo de 27 de abril de 2016. En los términos de la
          legislación aplicable, Sioslife S.A. se compromete a ofrecer al
          Usuario cuyo registro se recopila el derecho a acceder, oponerse,
          rectificar y suprimir los datos respectivos, según lo estipulado en la
          Política de Privacidad y Protección de Datos disponible en{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          .
        </Typography>
      </div>
    </LegalDocument>
  );
}

TermsAndConditions.propTypes = {
  launcher: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
  launcher: false,
};

export default TermsAndConditions;
