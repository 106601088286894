/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";
import Layout from "root/components/Layout";
import SEO from "root/components/SEO";
import Navbar from "root/components/Navbar";
import LegalHero from "root/sections/LegalHero";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import styles from "./pages.module.css";

function LegalDocument({ children, currentPage, title, bodyTitle, launcher }) {
  const color = "blue";

  if (launcher) {
    return (
      <div className={styles.launcher}>
        <SEO title={title} noDescription keywords="" />
        <Layout title={title}>
          <LegalHero title={bodyTitle} color={color}>
            {children}
          </LegalHero>
        </Layout>
      </div>
    );
  }

  return (
    <div>
      <SEO title={title} noDescription keywords="" />
      <Layout title={title}>
        <Navbar currentPage={currentPage} color={color} blueLogin="true" />
        <LegalHero title={bodyTitle} color={color}>
          {children}
        </LegalHero>
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
}

LegalDocument.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  bodyTitle: PropTypes.string.isRequired,
  launcher: PropTypes.bool,
  currentPage: PropTypes.string.isRequired,
};

LegalDocument.defaultProps = {
  launcher: false,
};

export default LegalDocument;
